import React from 'react';

import { Container } from '../../src/components/Grid';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import createContext from '../../src/utils/format/createContext';
import dynamic from 'next/dynamic';
import { translate } from 'react-i18next';
import useMobile from '../../src/utils/useMobile';
import getCategories from '../../src/apollo/graphql/category/getCategories';
import { media } from '../../src/styles/media';
import styled from 'styled-components';
import CurvedText from './CurvedText';

const CategorySlidesCarousel = dynamic(
  import('../../src/components/Carousel/CategorySlidesCarousel'),
  {
    // eslint-disable-next-line react/display-name
    loading: () => <div />,
    ssr: false
  }
);

const Wrapper = styled.div`
  padding-top: 120px;
  padding-bottom: 80px;
  background: ${({ backgroundUrl, theme }) =>
    backgroundUrl
      ? `${
          theme.color.brand.lightBlue
        } url('${backgroundUrl}') no-repeat center center`
      : theme.color.brand.lightBlue};
  background-size: cover;

  ${media.tablet`
    padding-top: 180px;
  `};
`;

const CategorySlides = ({ i18n, title, backgroundUrl }) => {
  const { isMobile } = useMobile();

  return (
    <Query
      query={getCategories}
      context={createContext(i18n.language)}
      variables={{ show_on_homepage: true }}
    >
      {({ loading, error, data }) => {
        if (!loading && !error && data && data.getCategories) {
          const categorySlides = data.getCategories;

          return (
            <Wrapper backgroundUrl={backgroundUrl}>
              <Container>
                <CurvedText isMobile={isMobile} title={title} />

                <CategorySlidesCarousel
                  slides={categorySlides}
                  isMobile={isMobile}
                />
              </Container>
            </Wrapper>
          );
        }
        return null;
      }}
    </Query>
  );
};

CategorySlides.propTypes = {
  i18n: PropTypes.object,
  title: PropTypes.string.isRequired,
  backgroundUrl: PropTypes.string
};

export default translate()(CategorySlides);

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircleType from 'circletype';
import { media } from '../../src/styles/media';

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.font.brand.accent};
  font-size: 40px;
  color: ${({ theme }) => theme.color.brand.white};
  margin-bottom: -80px;

  ${media.tablet`
    font-size: 71px;
    margin-bottom: -100px;
  `};
`;

const CurvedText = ({ isMobile, title }) => {
  const circleInstance = useRef();

  useEffect(
    () => {
      if (circleInstance.current) {
        new CircleType(circleInstance.current).radius(isMobile ? 185 : 300);
      }
    },
    [isMobile, circleInstance]
  );

  return <Wrapper ref={circleInstance}>{title}</Wrapper>;
};

CurvedText.propTypes = {
  isMobile: PropTypes.bool,
  title: PropTypes.string
};

export default CurvedText;

import gql from 'graphql-tag';

export default gql`
  query getCategories($show_on_homepage: Boolean) {
    getCategories(filter: { show_on_homepage: $show_on_homepage }) {
      id
      image
      updated_at
      detail {
        id
        label
        title
        description
        url
        language_id
      }
    }
  }
`;
